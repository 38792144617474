*{
    margin: 0;
    padding: 0;
    
  }
  
  .header{
    height: 80px;
    background: linear-gradient(48deg,#4d3bb3,#8830c9);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .title{
    color: white;
  }
  .whole{
    display: flex;
    gap: 50px;
    justify-content: center;
    margin: auto 10%;
  
  
  }
  .JSON,.instructions{
    display: flex;
    align-items: center;
    flex-direction: column;
    /* margin: 50px; */
    width: 80%;
    
  }
  .instructions{
    /* box-shadow: 0 5px 10px rgb(0 0 0 / 30%); */
    border-radius: 18px;
    height: fit-content;
    position: sticky;
    top: 20px;
    padding: 20px;
  }
  
  .rjsf,.JSONload,.download{
    border-radius: 18px;
    box-shadow: 0 5px 10px rgb(0 0 0 / 30%);
    width: 120%;
    padding: 20px;
  
  }
  fieldset{
    border: none ;
  }
  #root__title{
    font-weight: bold;
  }
  .btn-add,.update,.array-item-remove,.download,.submit,.array-item-move-up,.array-item-move-down{
    background: linear-gradient(48deg,#4d3bb3,#8830c9);
    border-radius: 8px;
    width: 100%;
    border: none;
    padding: 10px;
    color: white;
    margin: 10px 0px;
  }
  .btn-add:hover,.update:hover,.array-item-remove:hover,.download:hover,.submit:hover,.array-item-move-up:hover,.array-item-move-down:hover{
    background: linear-gradient(48deg,#372a80,#5a297e);
    cursor: pointer;
  
  }
  .array-item-remove{
    background: linear-gradient(48deg,#000000,#383838);
  
  }
  .download{
    margin: 10px auto;
    padding: 20px !important;
    font-size: 30px;
  }
  
  input{
    box-sizing: border-box;
    margin: 0;
    padding: 4px 11px;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    color: rgba(0,0,0,.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 20px;
    transition: all .3s;
  }
  
  input:focus,input:hover{
    border-color: #705ebf;
      box-shadow: 0 0 0 2px rgb(77 59 179 / 20%);
      border-right-width: 1px!important;
      outline: 0;
  }
  
  .form-group{
    padding: 3px 0px;
  }
  .btn-add:after {
    content: "Add question";
    visibility: visible;
    width: 100px;
    height: 10px;
    border-radius: 10px;
  }
  
  .array-item-remove:after{
    content: "Remove question";
    visibility: visible;
    width: 100px;
    height: 10px;
    border-radius: 10px;
  }
  .array-item-move-up:after{
    content: "Move up";
    visibility: visible;
    width: 100px;
    height: 10px;
    border-radius: 10px;
  }
  .array-item-move-down:after{
    content: "Move down";
    visibility: visible;
    width: 100px;
    height: 10px;
    border-radius: 10px;
  }
  form,.download{
    text-transform: capitalize;	
  
  }
  
  .form-group.field.field-object{
    border-bottom: rgb(197, 197, 197) solid 2px;
  }
  
  .addjson{
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
    transition: 0.3s;
    justify-content: center;
    border: dashed gray 2px;      
    position: relative;
    cursor: pointer;
  }
  .filetext{
    position: absolute;
  }
  .json{
    width: 100%;
    height: 120px;
    cursor: pointer;
    opacity: 0;
  
  }
  .btn-group{
    border-bottom: rgb(197, 197, 197) solid 2px;
    gap: 10px;
    
  }
  @media screen and (max-width:1050px) {
    .whole{
      flex-direction: column-reverse;
      align-items: center;
  margin-bottom: 100px;
      gap: 0px;
    }
    .JSON,.instructions{
      width: 80%;
      margin: 0px;
    }
    .instructions{
      position: relative;
      margin-bottom: 40px;
    }
    
  }