$gradient: linear-gradient(253deg, #8830c9 0%, #4d3bb3 100%);
.card {
  position: relative;
  min-width: 200px;
  height: 290px;
  margin-top: 2rem;
}
.card .box {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 20px;
    top: 20px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    transition: 0.5s;
    border-radius: 1rem;
  }
.card button:hover {
    background: $gradient;
  }
.card:hover .box {
  transform: translateY(-50px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
  background: linear-gradient(253deg, #4d3bb3 0%, #8830c9 100%);
}
.card:hover .box p {
    color: #fff;
  }
