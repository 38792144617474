.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 100;
}
  .modal-wrapper svg {
    max-height: 90%;
    max-width: 90vw;
    background: transparent;
    box-shadow: 1rem 1.7rem 3.2rem rgb(0 0 0 / 90%);
    border-radius: 50px;
  }
  .modal-wrapper .share {
    --size: 10rem;
    --radius: 25rem;
    --padding: 2rem;
    --bg-color: rgba(255, 255, 255, 0.9);
    --fg-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    bottom: var(--padding);
    right: var(--padding);
  }
  .modal-wrapper .share > * {
        position: absolute;
        display: grid;
        place-content: center;
        border-radius: 50%;
        background: var(--bg-color);
        color: var(--fg-color);
        text-decoration: none;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.6);
        font-size: 4rem;
        cursor: pointer;
      }
  .modal-wrapper .share > * svg {
          box-shadow: none;
        }
  .modal-wrapper .share > .action {
        --factor: 0;
        width: var(--size);
        height: var(--size);
        right: calc(0.35 * var(--size));
        bottom: calc(0.35 * var(--size));
        opacity: 0;
        transform: rotate(calc(-1 * var(--angle)))
          translateY(calc(-1 * var(--radius) * var(--factor)))
          rotate(var(--angle));
        transition: transform 250ms ease-in-out, opacity 250ms ease-in-out,
          box-shadow 250ms ease-in-out, color 250ms ease-in-out;
      }
  .modal-wrapper .share > .action:hover {
          box-shadow: 1rem 1.7rem 3.2rem rgb(0 0 0 / 50%);
        }
  .modal-wrapper .share > .share-button:hover {
        color: var(--hi-color);
        box-shadow: 1rem 1.7rem 3.2rem rgb(0 0 0 / 50%);
      }
  .modal-wrapper .share.open > .action {
        --factor: 1;
        opacity: 1;
      }
  .modal-wrapper .share.open > .share-button > i {
        transform: rotate(-135deg);
      }
  .modal-wrapper .share > .action:nth-child(1) {
          --angle: 0deg;
          transition-delay: 0ms;
        }
  .modal-wrapper .share > .action:nth-child(2) {
          --angle: 30deg;
          transition-delay: 50ms;
        }
  .modal-wrapper .share > .action:nth-child(3) {
          --angle: 60deg;
          transition-delay: 100ms;
        }
  .modal-wrapper .share > .action:nth-child(4) {
          --angle: 90deg;
          transition-delay: 150ms;
        }
  .modal-wrapper .share > .share-button {
        width: calc(1.3 * var(--size));
        height: calc(1.3 * var(--size));
        bottom: 0;
        right: 0;
        font-size: 6rem;
        transition: box-shadow 250ms ease-in-out, color 250ms ease-in-out;
      }

/* Modal Header */

.header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 30px;
  word-wrap: break-word;
}

.content {
  z-index: 999;
  background-image: linear-gradient(to top, rgb(153, 41, 234), rgb(88, 8, 251));
  margin: 15% auto;
  border-radius: 25px;
  padding-left: 4% ;
  padding-right:4%;
  padding-top: 1%;
  padding-bottom: 1%;
  width: 500px;
  text-align: center;
  height:-moz-fit-content;
  height:fit-content;
  position: relative;
}

.content form input {
      box-sizing: border-box;
      margin: 8px 0;
      padding: 0;
      font-variant: tabular-nums;
      list-style: none;
      font-feature-settings: 'tnum';
      position: relative;
      display: inline-block;
      width: 100%;
      min-width: 0;
      padding: 4px 5px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 20px;
      
      background-color: #fff;
      background-image: none;
      border: 1px solid #d9d9d9;
      transition: all 0.3s;
      border-radius: 8px;
      font-weight: 700;
      text-align: center;
    }

.content form button {
      line-height: 1.5715;
      position: relative;
      font-weight: 400;
      white-space: nowrap;
      text-align: center;
      border: 1px solid transparent;
      cursor: pointer;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      -webkit-user-select: none;
      user-select: none;
      touch-action: manipulation;
      padding: 4px 30px;
      font-size: 20px;
      border-color: #d9d9d9;
      background: #fff;
      margin: 20px auto;
      height: 40px;
      display: block;
      border-radius: 8px;
      width: 100%;
    }

.content p {
    font-size: 30px;
    color: rgb(175, 14, 14);
    text-align: center;
  }

/* The Close Button */

.close {
  color: rgb(227, 227, 227);
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.title {
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;

}
