.button {
  padding: 10px;
  display: inline;
  border-radius: 2px;
  font-family: 'Arial';
  border: 0;
  margin: 0 10px;
  background: linear-gradient(#4d3bb3, #8830c9);
  font-size: 15px;
  line-height: 15px;
  color: white;
  width: auto;
  height: auto;
  box-sizing: content-box;
  border-radius: 0.5rem;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
}
