.right {
    display: flex;
  justify-content: space-around;
  align-items: flex-start;
  height: 100%;
}
.stickeypreview {
    position: sticky;
    bottom: 0.5rem;
    align-self: flex-end;
    bottom: 15em;
    width: 100%;
}