.bg_area {
    background-image: linear-gradient(to top, rgb(153, 41, 234), rgb(88, 8, 251));
    position: absolute;
    width: 100%;
    background-repeat: no-repeat;
    background-size: auto 100%;
    top: 0px;
    left: 0px;
    
}
@media screen and (min-width: 1200px) {
.cards {
    min-height: 400px;
    padding-bottom: 20px;
}
}
/* .cards_bg {
    box-shadow:rgb(135 150 158 / 35%) 0px 4px 20px;
} */