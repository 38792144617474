.left {
  display: flex;
  height: 100%;
  align-items: flex-start;
}
@media (orientation: landscape) {
.stickeypreview {
    position: sticky;
    bottom: 20em;
    width: 100%;
    align-self: flex-end;
}

.stickeypreview2 {
  position: sticky;
  top:0;
  width: 25%;
  align-self: flex-start;
}

}